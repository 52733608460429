import { useRef } from 'react';
import { Box, Flex } from '../primitives';
import GlobalSearch from './GlobalSearch';
import { useRouter } from 'next/router';
import { useHotkeys } from 'react-hotkeys-hook';
import Link from 'next/link';
import { ConnectWalletButton } from 'components/ConnectWalletButton';
import NavItem from './NavItem';
import ThemeSwitcher from './ThemeSwitcher';
import HamburgerMenu from './HamburgerMenu';
import MobileSearch from './MobileSearch';
import { useTheme } from 'next-themes';
import { useMediaQuery } from 'react-responsive';
import { useMarketplaceChain, useMounted } from '../../hooks';
import { useAccount } from 'wagmi';
import CartButton from './CartButton';
import { AccountSidebar } from 'components/navbar/AccountSidebar';

export const NAVBAR_HEIGHT = 81;
export const NAVBAR_HEIGHT_MOBILE = 77;

const Navbar = () => {
  const { theme } = useTheme();
  const { isConnected } = useAccount();
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const isMounted = useMounted();
  const { routePrefix } = useMarketplaceChain();

  let searchRef = useRef<HTMLInputElement>(null);

  const router = useRouter();
  useHotkeys('meta+k', () => {
    if (searchRef?.current) {
      searchRef?.current?.focus();
    }
  });

  if (!isMounted) {
    return null;
  }

  return isMobile ? (
    <Flex
      css={{
        height: NAVBAR_HEIGHT_MOBILE,
        px: '$4',
        width: '100%',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: 'black',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="end"
      justify="between"
    >
      <Box css={{ flex: 1 }}>
        <Flex align="end">
          <Link href={`/${routePrefix}`}>
            <Box css={{ width: 34, cursor: 'pointer' }}>
              <MobileSearch key={`${router.asPath}-search`} />
            </Box>
          </Link>
        </Flex>
      </Box>
      <Flex align="end" css={{ gap: '$3' }}>
        <CartButton />
        <HamburgerMenu key={`${router.asPath}-hamburger`} />
      </Flex>
    </Flex>
  ) : (
    <Flex
      css={{
        height: NAVBAR_HEIGHT,
        px: '$5',
        width: '100%',
        maxWidth: 1920,
        mx: 'auto',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: 'black',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="end"
      justify="start" // Align items to the left
    >
      <Box css={{ flex: '0 0 auto', mr: '$5', ml: '-75px' }}> {/* No logo, just search */}
        <GlobalSearch
          ref={searchRef}
          placeholder="Search OKINA Labs collections"
          containerCss={{ width: '100%' }}
          key={router.asPath}
        />
      </Box>
      <Flex css={{ gap: '$5', ml: '$5' }} align="end"> {/* Added margin-left for spacing */}
        <Link href={`/${routePrefix}/collection-rankings`}>
          <NavItem active={router.pathname.includes('collection-rankings')}>
            OKINA Labs
          </NavItem>
        </Link>
        <Link href="/portfolio">
          <NavItem active={router.pathname == '/portfolio'}>Sell</NavItem>
        </Link>
      </Flex>

      <Flex css={{ gap: '$3' }} justify="end" align="end">
        <ThemeSwitcher />
        <CartButton />
        {isConnected ? (
          <AccountSidebar />
        ) : (
          <Box css={{ maxWidth: '185px' }}>
            <ConnectWalletButton />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default Navbar;
